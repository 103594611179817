<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/QCGF' }">质控指标</el-breadcrumb-item>
      <el-breadcrumb-item>共识学习</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <div class="carditem" v-for="t in list" :key="t.Link">
        <VideoCard :content="t" @play-click="playClick" />
      </div>
    </div>
  </div>
</template>

<script>
import { GetQCVideos } from "@/api";
import VideoCard from '@/components/videoCard/videoCard'

export default {
  name: "LearnAndDiscussion",
  data() {
    return {
      list: [],
    };
  },
  methods: {
    async GetQCVideosAsync() {
      const r = await GetQCVideos();
      if (r.code == 1) {
        this.list = r.data;
      } else {
        this.$message.error(r.error.Message);
      }
    },
    playClick(content) {
      sessionStorage.videoccLink = content.Link
      this.$router.push({
        path: `/VideoLesson/${content.Id}`,
      })
    }
  },
  created() {
    this.GetQCVideosAsync()
  },
  components: {
    VideoCard,
  }
};
</script>

<style scoped>
.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap;
  margin-top: 20px;
}

.carditem {
  padding: 10px 25px;
}
</style>