<template>
  <div class="card">
    <el-card :body-style="{ padding: '0px' }">
      <img :src="content.ThumLink" class="image" />
      <div style="padding: 14px">
        <span>{{ content.Title }}</span>
        <div class="bottom clearfix">
          <time class="time">讲师：{{ content.DoctorName }}</time>
          <el-button type="text" class="button" @click="onClick">查看</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "VideoCard",
  props: {
    content: {
      type: Object,
      default() {
        return {
          DoctorName: "",
          Hospital: "",
          Link: "",
          ThumLink: "",
          Title: "",
          Id:"",
        };
      },
    },
  },
  data() {
    return {
    };
  },
  methods:{
    onClick(){
      this.$emit('play-click', this.content)
    }
  }
};
</script>

<style scoped>
.card {
  width: 300px;
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>